import React, { useEffect, useMemo, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { PayPalButtons } from '@paypal/react-paypal-js'
import StripeCheckoutForm from '../components/StripeCheckoutForm'
import { Alert, Spinner } from 'flowbite-react'
import { useNavigate } from 'react-router-dom'
import { BASE_URL, BASE_WEB_URL } from '../config/environment'
import { useStripePaymentIntent } from '../hooks/useStripePaymentIntent'
import { Heading } from '../components/Heading'
import { ScreenLayout } from '../components/ScreenLayout'
import { Button } from '../components/Button'
import { IOrderHorse, IPackage, useCheckoutState } from '../state/checkout'
import {FaArrowLeft, FaCreditCard, FaPaypal} from 'react-icons/fa'
import { useOrdersCreate } from '../hooks/useOrdersCreate'
import { useAuthState } from '../state/auth'
import TagManager from "react-gtm-module";

type PaymentMethod = 'Stripe' | 'PayPal'

const Checkout = () => {
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>('Stripe')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { amount, orderHorses, couponId, setAmount, setOrderId, orderId, clientSecret, setClientSecret, buyerToken, setBuyerToken } = useCheckoutState()
  const [isProcessing, setProcessing] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const createOrder = useOrdersCreate()
  const { user } = useAuthState()

  const navigate = useNavigate()
  const createStripePaymentIntent = useStripePaymentIntent()
  
  const ppClientId = import.meta.env.VITE_APP_PAYPAL_CLIENT_ID

  const stripePromise = useMemo(
    () =>
      loadStripe(import.meta.env.VITE_APP_STRIPE_API_KEY as string, {
        apiVersion: '2022-11-15',
      }),
    [],
  )

  // Load Stripe payment intent
  useEffect(() => {
    setIsLoading(true)

    createStripePaymentIntent({
      amount,
      description: getOrderDescription(),
      receiptEmail: user.email,
    })
      .then((res) => {
        const { clientSecret, token } = res.data
        setClientSecret(clientSecret)
        setBuyerToken(token)
        setIsLoading(false)
      })
      .catch((e) => {
        console.error('Checkout#createStripePaymentIntent', e)
        setIsLoading(false)
      })
  }, [])

  const getOrderDescription = () => {
    const orderName = `Order #${orderId}; `;
    const description = orderName + orderHorses.map((oh: IOrderHorse) => `${oh.name}: ${getOrderPackagesDescription(oh)}`).join("; ")
    return description
  }

  const getOrderPackagesDescription = (horse: IOrderHorse) => {
    return horse.packages.map(pkg => pkg.name).join(", ")
  }

  //  Creates paypal order
  async function createPaypalOrder(data: any, actions: any): Promise<string> {
    // setIsLoading(true);
    return fetch(
      `${BASE_URL}/api/PaypalOrder?` +
        new URLSearchParams({
          amount: String(amount),
          description: getOrderDescription()
        }),
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      },
    )
      .then((res) => res.json())
      .then((res) => {
        if (!res) return
        const ppOrderId: string = res['data']['payPalOrderId']
        return ppOrderId
      })
      .catch((error) => {
        console.error('Checkout#createPaypalOrder', error)

        return error
      })
  }

  const createCurrentOrder = async () => {
    try {
      const res = await createOrder({
        horses: orderHorses.filter((horse) => horse.packages?.length),
        paymentMethod: 'PayPal',
        coupon: !couponId ? undefined : { id: couponId },
        transactionId: clientSecret,
        buyerToken
      })
      const { amount, token, id } = res.data
      setAmount(amount)
      setOrderId(id);
      return id;
    } catch (err) {
      console.error("Failed to create an order: ", err);
      return NaN;
    }
  }

  if (isLoading)
    return (
      <div className="mt-12 text-center flex flex-col items-center justify-center">
        <Spinner className="fill-edxred-500" size="lg" aria-label="Center-aligned spinner" />
        <p className="whitespace-pre-wrap">
          Please wait while we process your order...{'\n'}Do not refresh or close your browser.
        </p>
      </div>
    )

  return (
    <ScreenLayout>
      <Heading>Checkout - Payment Method</Heading>

      {(
        <>
          <div className="mt-auto">
            <h3 className="mx-auto w-fit text-xl font-bold">
              Order Total: <span className="text-edxred-500">${Number(amount) / 100}</span>
            </h3>
          </div>

          {Number(amount) > 0 && (
            <div className="relative">
              {isProcessing && (
                <>
                  <div className="bg-gray-300/50 rounded flex flex-col justify-center items-center absolute z-10 left-0 top-0 w-full h-full">
                    <Heading>Processing Payment</Heading>

                    <div className="w-full h-24 flex items-center justify-center">
                      <Spinner className="animate animate-spin fill-edxred-500" size="lg" />
                    </div>
                  </div>
                </>
              )}
              {!isProcessing && (
                <div className="mt-2 grid grid-cols-2 gap-x-2">
                  <Button
                    {...(paymentMethod === 'Stripe' ? {} : { outline: true })}
                    onClick={() => setPaymentMethod('Stripe')}
                  >
                    <FaCreditCard className="mr-1" /> Pay with Card
                  </Button>

                  <Button
                    className="text-yellow-500 border-blue-700 font-semibold"
                    {...(paymentMethod === 'PayPal' ? {} : { outline: true })}
                    onClick={() => setPaymentMethod('PayPal')}
                  >
                    <FaPaypal className="text-blue-500 mr-1" /> Pay with Paypal
                  </Button>
                </div>
              )}

              {stripePromise && clientSecret && (
                <div className={`mt-5 ${paymentMethod === 'Stripe' ? '' : 'hidden'}`}>
                  <Elements stripe={stripePromise} options={{ clientSecret }}>
                    <StripeCheckoutForm onProcessingChange={setProcessing} />
                  </Elements>
                </div>
              )}

              {ppClientId && (
                <div className={'mt-5 ' + (paymentMethod === 'PayPal' ? '' : 'hidden')}>
                  <PayPalButtons
                    createOrder={createPaypalOrder}
                    onApprove={async (data, actions) => {
                      const details = await actions.order?.capture()
                      if (details?.status === 'COMPLETED') {
                        const orderId = await createCurrentOrder();
                        console.log("Event: Purchase");
                        console.log("Order Id: " + orderId);
                        console.log("Order Total: " + (amount/100));
                        if (amount && orderId) {
                            // Google Tag Manager tracker
                            TagManager.dataLayer({
                                dataLayer: {
                                    event: 'Purchase',
                                    ecommerce: {
                                        transaction_id: orderId,
                                        value: amount / 100,
                                        currency: 'USD',
                                    },
                                },
                            })
                          navigate('/checkout-success')
                        } else {
                          setErrorMsg("We're sorry, but we encountered an error processing your request.")
                        }
                      }
                    }}
                    onError={(error) => console.error('Checkout# PayPalButtons onError', error)}
                  />
                </div>
              )}

                <div
                    className="text-gray-500 mt-4 flex items-center cursor-pointer space-x-2"
                    onClick={() => navigate(-1)}
                >
                    <FaArrowLeft /> <span>Go Back</span>
                </div>
            </div>
          )}
        </>
      )}
    </ScreenLayout>
  )
}

export default Checkout
